@import url('https://fonts.googleapis.com/css?family=Dosis:400,700|Roboto:300,400,700,900');
// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';
@import 'addons/bootstrap/bootstrap/acordeon';
@import 'addons/bootstrap/bootstrap/modals';
@import 'addons/font-awesome/font-awesome';
@import 'addons/magnific-popup/magnific-popup';
//@import 'addons/owl-carousel2/owl.carousel';
@import 'addons/layerslider/layerslider';
//@import 'addons/cube-portfolio/cubeportfolio';

// IMPORT TEMPLATE
@import 'functions';

/*****************************************************/

// FONTS
$font-1: "Roboto";
$font-2: "Dosis";
$font-3: "FontAwesome";
/*****************************************************/
/* GENERAL TEMPLATE */
body {
	color: #000000;
	font-size: 10px;
	font-weight: 400;
	font-family: $font-1;
	line-height: 100%;
	a {
		text-decoration: none !important;
		&:hover, &:focus {
			text-decoration: none !important;
		}
	}
	a[href^=tel], a[href^=mailto] {
		&:hover {

		}
	}
	h1.referencement {
		display: none !important;
	}
	.appear {
		opacity: 0;
		@include transition(opacity 0.75s ease-in);
	}
	.parallax, .parallax-slow, .parallax-fast {
		background-attachment: fixed;
		@media screen and (max-width: 769px) {background-attachment: scroll;}
	}
	.top {
		@media screen and (max-width: 991px) {height: 463px;}
		@media screen and (max-width: 769px) {height: 100%;}
	}

	.triggers {
		.prof {
			position: fixed;
			right: 0;
			top:120px;
			z-index: 1000;
			background-color: #E1503B;
			width: 100px;
			height: 75px;
			transition: all 0.2s ease-in;
			&:hover {
				width: 120px;
				a {
					color: black;
				}
			}
			@media screen and (max-width: 767px) {width:90px; height: 70px;}
			@media screen and (max-width: 430px) {width:80px; height: 65px; top:110px;}
			@media screen and (max-width: 330px) {width:70px; height: 60px; top:110px;}
			&::before {
				border-left: 30px solid transparent;
				border-right: 30px solid #E1503B;
				border-top: 38px solid #E1503B;
				content: "";
				left: -30px;
				position: absolute;
				z-index: 999;
			}
			a {
				position: relative;
				z-index: 1001;
				text-align: right;
				display: block;
				font-size: 14px;
				line-height: 1.2;
				text-transform: uppercase;
				font-weight: 700;
				padding-top: 12px;
				padding-right:10px;
				color: #fff;
				transition: all 0.2s ease-in;
				@media screen and (max-width: 767px) {font-size: 12px;}
				@media screen and (max-width: 430px) {font-size: 11px;}
				@media screen and (max-width: 330px) {font-size: 10px;}
				//background: url("../images/portail.png") no-repeat;
			}
			&::after {
				border-bottom: 41px solid #E1503B;
				border-left: 30px solid transparent;
				border-right: 30px solid #E1503B;
				bottom: 0;
				content: "";
				left: -29px;
				position: absolute;
				z-index: 999;
			}
		}
	}
  .section01 {
		margin-top: -80px;
		position: relative;
		z-index:-0;
	  #primaire {
			    padding-top: 105px;
					padding-bottom: 40px;
		}
		.container-img {
			position: relative;
			padding-left: 125px;
			padding-right: 125px;
			img {width: 100%;}
			#img-1{
				background: url(../images/mod-01.jpg);
				height: 250px;
				background-size: 96% 100%;
				background-repeat: no-repeat;
					@media screen and (max-width: 767px) {background-size:100%;}
					@media screen and (max-width: 430px) {height: 190px;}
					@media screen and (max-width: 330px) {height: 150px;}
				}
      #img-1:hover {
       background-image: url(../images/mod-01-hover.jpg);
			 height: 250px;
        a {color: #174591;}
				@media screen and (max-width: 430px) {height: 190px;}
				@media screen and (max-width: 330px) {height: 150px;}
      }
			#img-2 {
				background: url(../images/mod-02.jpg);
				height: 250px;
				background-size: 96% 100%;
				background-repeat: no-repeat;
					@media screen and (max-width: 767px) {background-size:100%;}
					@media screen and (max-width: 430px) {height: 190px;}
					@media screen and (max-width: 330px) {height: 150px;}
			}
			#img-2:hover {
			 background-image: url(../images/mod-02-hover.jpg);
			 height: 250px;
			 a {color: #e1503a;}
			@media screen and (max-width: 430px) {height: 190px;}
			@media screen and (max-width: 330px) {height: 150px;}
			}
			@media screen and (max-width: 991px) {
				padding-left: 15px;
				padding-right: 15px;
				.col {padding: 0;}
			}
	  }
		h1 {
			font-size: 48px;
			text-align: center;
			font-family: "Dosis";
			padding-bottom: 34px;
			font-weight: 900;
		}
		p {
			line-height: 1.5;
			text-align: center;
			font-size: 16px;
			font-size: 1.6rem;
			font-weight: normal;
		}
		a {
			right: 50px;
			bottom: 35px;
			font-size: 35px;
			font-family:$font-2;
			font-weight: bold;
			position: absolute;
			color: white;
			@media screen and (max-width: 1192px) {font-size: 35px;}
			@media screen and (max-width: 992px) {font-size: 30px;}
			@media screen and (max-width: 767px) {font-size: 55px;}
			@media screen and (max-width: 550px) {font-size: 30px;}
		}
		@media screen and (max-width: 991px) {margin-top: -125px;}
		@media screen and (max-width: 880px) {margin-top: -190px;}
		@media screen and (max-width: 769px) {margin-top: -70px;}
	}
	.section02 {
		color: white;
		padding-top: 105px;
  	background-image: url(../images/section02-BG.jpg);
    background-repeat: no-repeat;
    background-position: center top;
		@media screen and (max-width: 768px) {
			background-color: #e7e4df;
			background-image: none;
			padding-top: 60px;
		}
		#dates {
			padding-left: 10px;
			padding-right: 10px;
		}
		.dates {
			.date-item {
				background-color: #174591;
				margin-bottom: 100px;
				@media screen and (max-width: 992px) {
					margin-bottom: 10px;
				}
				.caption {
					margin-right: -15px;
					margin-left: -15px;
				}
				&:nth-child(1) {
					.date-box{background-color: #174591;}
					.caption {
						background-color: #697fa9;
						p{
							padding: 20px;
						  margin-bottom: 0px;
						}
					}
				}
				&:nth-child(2) {
					background-color: #e1503a;
					.caption {
						background-color: #e38b7c;
						p{
							padding: 20px;
						  margin-bottom: 0px;
						}
					}
				}
				&:nth-child(3) {
					background-color: #05c199;
					.caption {
						background-color: #59c3a8;
						p{
							padding: 20px;
						  margin-bottom: 0px;
						}
					}
				}
				h3{
					font-size: 48px;
					text-align: left;
					font-family: "Dosis";
					margin-left: 30px;
					margin-top: 30px;
					margin-bottom: 30px;
				  font-weight: 600;
				}
				p {
					font-family: Roboto;
					line-height: 1;
					text-align: justify;;
					font-size: 18px;
					font-weight: 200;
					padding-top: 40px;
					padding-left: 30px;
					padding-right: 30px;
				}
			}
		}
		h1 {
			color: black;
			font-size: 48px;
			text-align: center;
			font-family: "Dosis";
			padding-bottom: 65px;
			font-weight: 900;
		}
	}
	.section03{
			padding-top: 90px;
			padding-bottom: 90px;
			@media screen and (max-width: 768px) {
				padding-top: 40px;
				padding-bottom: 40px;
			}
			.message-container {position: relative;}
	    .row{
			 	padding-top: 30px;
				padding-bottom: 30px;
		  }
		 .fixed {
			 @media screen and (max-width: 768px) {position: fixed;}
		 }
		.message01 {
			background-color:#ededed;
			font-size: 18px;
			line-height: 1.3em;
			padding-bottom: 55px;
			padding-top: 40px;
			margin-left: 30;
			display: block;
			h3 {font-size: 30px;}
			.message-icons-box {
				margin: 20px auto;
				display: flex;
				align-items: center;
				justify-content: center;
				a {
					& > div {
						background-position: center;
						background-size: 100% 100%;
						background-repeat: no-repeat;
						height: 50px;
						width: 50px;
						margin: 50px 30px 0;
						&:hover {
							opacity: 0.7;
						}
						&.video-message {background-image: url('../images/icon_video.png');}
						&.img-message {background-image: url('../images/icon_photo.png');}
						&.pdf-message {background-image: url('../images/icon_pdf.png');}
						&.link-message {background-image: url('../images/icon_link.png');}
					}
				}
			}

		}
		.message02 {
			background-color:#ededed;
			font-size: 18px;
			line-height: 1.3em;
			padding-bottom: 55px;
			padding-top: 60px;
			display: block;
			//position: absolute;
			h3 {font-size: 30px;}
		}
		.titre-blue {
			color: #174591;
			height: 119px;
			left: -120px;
			top: 80px;
			position: relative;
			@media screen and (max-width: 768px) {
				top: 0px;
				font-size: 45px;
				z-index: 10;
				left: 0%;
			}
			h1 {
				font-size: 71px;
				font-weight: bold;
				font-family: $font-2;
				&:after {
					left: 0;
					bottom: -15px;
					width: 130px;
					height: 10px;
					content: '';
					display: block;
					border-radius: 15px;
					background: #174591;
				}
				@media screen and (max-width: 991px) {font-size: 58px;}
				@media screen and (max-width: 768px) {
					font-size: 45px;
					top: -9.0em;
					left: 0%;
				}
			}
		}
		.titre-red {
			color: #e1503a;
			left: -120px;
			top: 80px;
			position: relative;
			@media screen and (max-width: 768px) {
				font-size: 45px;
				top: 0px;
				left: 0%;
				z-index: 10;
				top: 50px;
			}
			h1 {
				font-size: 71px;
				font-weight: bold;
				font-family: $font-2;
				&:after {
					left: 0;
					bottom: -15px;
					width: 130px;
					height: 10px;
					content: '';
					display: block;
					border-radius: 15px;
					background: #e1503a;
				}
				@media screen and (max-width: 991px) {font-size: 58px;}
				@media screen and (max-width: 768px) {
					font-size: 45px;
					top: -3em;
					left: 0%;
				}
			}
		}
	}
	.footer {
		color: #f5fdfb;
		font-size: 14px;
		line-height: 180%;
		padding: 20px 0;
		text-align: center;
		background-color: #05c199;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		a {
			color: #fff;
			font-size: 14px;
		}
		img {
			top: -3px;
			position: relative;
		}
		@media screen and (max-width: 768px) {background-attachment: scroll;}
	}
}

/*****************************************************/
/* POPUP */
.popup {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	position: fixed;
	cursor: pointer;
	background: rgba(255, 255, 255, 0.50);

}

.popup-box {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	color: black;
	padding: 0px;
	display: table;
	background: #fff;
	min-width: 40%;
	min-height: 50%;
	padding: 2%;
	//overflow: auto;
}

.popup-box, .popup .logo {
	border: 10px solid #05c199;
}

.popup-box .close-btn {
	top: 0;
	right: 0;
	color: black;
	font-size: 20px;
	padding: 5px 7px;
	position: absolute;
	background: white;
}

.popup-box p, .popup-box div {
	font-size: 1.5rem;
	padding-bottom: 30px;
	line-height: 1.5;
}

.popup-box p:nth-last-child(1) {
	padding-bottom: 0;
}

.popup-box p b {
	font-size: 2em;
	line-height: 100%;
}

.popup-box h3 {
	color: #05c199;
	font-size: 2em;
}

.popup .logo {
	max-height: 150px;
	max-width: 150px;
	top: 25%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	box-sizing: border-box;
	z-index: 3000;
}
.popup .title {
	text-align: center;
	color: #05c199;
	text-transform: uppercase;
}
.popup .image img {
	width: auto;
	height: 50vh;
}
@media screen and (orientation: portrait) {
	.popup .image img {
		width: 75vw;
		height: auto;
	}
}
/*****************************************************/
.module-menu {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100px;
  z-index: 999;
	background: rgba(255,255,255,1);
/* pading de logo*/
	.logo {
		float: left;
 		//padding: 20px 15px;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 32px;
		@media screen and (max-width: 500px){width: 156px;}
	}
	//menu to right
	.main-menu {
		width: auto;
		height: 100%;
	  float: right;
		padding-top: 1px;
		@media screen and (max-width: 1265px){padding-top: 20px;}
		.menu-toggle {
			margin-right: 15px;
			float: right;
			display: none;
			cursor: pointer;
			padding: inherit;
			.bar {
				width: 30px;
				height: 2px;
				display: block;
				background: #05c199;
				&:nth-child(2) {margin: 5px 0;}
		  	}
			@media screen and (max-width: 1265px) {display: block;}
		}
		.menu-list {
			height: 100%;
			display: block;
	    //float: right;
			@media screen and (max-width: 1265px) {display: none;}
			.menu {
				margin: 0;
				height: 100%;
				float: right;
				list-style: none;
				li {
					margin: 0;
					height: 100%;
					float: left;
					position: relative;
					padding: 26px 20px;
					border-left: 1px solid #e5e5e5;
					list-style: none;
					margin: 0px;
					font-size: 15px;
					line-height: 2.5;
					//proporciona el ancho automatico al submenu en el 2do y 3r menu superior
					&:nth-child(3){
						.submenu {
							width: auto;
							white-space: nowrap;
						}
					}
					&:nth-child(4){
						.submenu {
							width: auto;
							white-space: nowrap;
						}
					}
	///hover sur le reste du menu------------------
						&:hover {
							.active-box {opacity: 1;}
						  a {color: #e5e5e5;}
							li a{color: black;}
					  }
					.active-box {
						top: 0;
						left: 0;
						right: 0;
						opacity: 0;
						height: 10px;
						position: absolute;
						background: #e5e5e5;
						@include transition(all 0.3s);
					}
					a {
						font-family: Roboto;
						font-size: 16px;
						font-weight: 400;
						color: #02111e;
					}
					.submenu {
						top: 99px;
						left: 0;
						width: 100%;
						margin: 0;
						padding: 0;
						height: auto;
						display: none;
						@include transition(all 0.2s);
						position: absolute;
						li {
							height: auto;
							border: 0;
							margin: 2px;
							padding: 1px 10px;
							float: none;
							display: block;
							background: #fff;

							@include transition(all 0.2s);
							&:hover {
								background: #e1503a;
								a {color: #fff;}
							}
						}
						@media screen and (max-width: 1265px) {position: relative;}
					}
					&.active {
						.active-box {opacity: 1;}
						a {color: #e5e5e5;}
					}
					&:hover {
						.submenu {display: block;}
					}
				}
			}
		}
	}
	.vert {
			float: right;
			background-color: #05c199;
	    height: 100%;
			background-image: url(../images/nav-zone.png);
			background-position: center;
			background-repeat: no-repeat;
			width: 220px;
				&:hover {
					background-color: black;
					background-image: url(../images/nav-zone-hover.png);
					background-position: center;
					background-repeat: no-repeat;
				}
				a {
					.link-zone {
						height: 100px;
						width: 220px;
					}
				}
					@media screen and (max-width: 1265px) {display: none;}
			}
}

/*****************************************************/
.slideshow-wrap {
	position: relative;
	.slideshow-next,
	.slideshow-prev {
		top: 50%;
		cursor: pointer;
		position: absolute;
		@include transform(translateY(-50%));
		@media screen and (max-width: 767px) {
			display: none !important;
		}
		img {
			display: block;
			&.hover {
				display: none;
			}
		}
		&:hover {
			img {
				display: none;
				&.hover {display: block;}
			}
		}
	}
	.slideshow-next {
		right: 20px;
	}
	.slideshow-prev {
		left: 20px;
	}
}
#layerslider,#layerslider-mobile {
	position: relative;
	z-index:-1;
	height: 45em;
}
.ls-nav-next {
	background-image: url(../images/SS-next.png);
	height: 50px;
	right: 10px;
	visibility: visible;
	display: none;
	&.ls-nav-active {
		background: #0076c6;
	}
}
.ls-nav-prev {
	background-image: url(../images/SS-next.png);
	height: 50px;
	left: 10px;
	visibility: visible;
	display: none;
	&.ls-nav-active {
		background: #0076c6;
	}
}
.ls-bottom-nav-wrapper {
	top: 45%;
	display: table;
	padding-left: 20px;
	position: absolute;
	@include transform(translateY(-50%));
}

/*****************************************************/
.bottom {
	background-image: url(../images/section01-bg.jpg);
}
/*-------------section 01---------------------------*/
#table {
	background-color: rgba(255,255,225,0);
	}
/*--------------------Section footer----------------*/
.module-footer {
	background-position: center;
	background-image: url("../images/section02-bg.jpg");
	h1 {color: #fff;}
	h4 {
		color: #fff;
		font-size: 16px;
		font-size: 1.6rem;
		text-transform: uppercase;
		padding: 235px, 0;
	  color: #fff;
		margin: 40px 0 20px;
		font-size: 4em;
		font-style: italic;
		font-weight: bold;
	}
	p {
  	line-height: 1;
		color: #ddd;
		font-size: 16px;
		font-size: 1.6rem;
		font-weight: 300;
	}
	.btn {
		color: #fff;
		display: block;
		margin: 0 auto;
		background: #0076c5;
		border-radius: 0;
	}
	ul {
		padding-top: 35px;
		color: #ddd;
		font-size: 16px;
		font-size: 1.6rem;
		font-weight: 300;
		border-bottom-color: #0076c5;
	}
	li {padding-bottom: 6px;}
}

/***************************************************/

#mobile-main-menu {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	overflow: hidden;
	position: fixed;
	background: #fff;
	@include transition(all 0.5s ease-in);
	@include transform(translate(0%, -100%));
	@media screen and (max-width: 1265px) {
		&.toggled {
			overflow-y: auto;
			@include transform(translate(0%, 0%));
		}
	}
	.menu-toggle {
		top: 40px;
		right: 15px;
		cursor: pointer;
		position: absolute;
		.bar {
			width: 30px;
			height: 2px;
			display: block;
			background: #05c199;
			&:nth-child(2) {margin: 5px 0;}
		}
	}
	ul {
		margin: 80px auto;
		padding: 0 30px;
		display: table;
		list-style: none;
		li {
			position: relative;
			text-align: center;
			font-size: 25px;
			font-weight: 400;
			line-height: 100%;
			padding: 5px;
			border-bottom: 1px solid #05c199;
			&:nth-last-child(1) {border-bottom: none;}
			a {color: #05c199;}
			.submenu {
				margin: 5px auto;
				li {
					border-bottom: none;
					font-size: 18px;
					a {color: #b9b9b9;}
				}
			}
		}
	}
}
/*****************************************************************/
/****************Mixins*******************************************/
/*****************************************************************/

@mixin top-style {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 500px;
	margin-top: 100px;
	h1 {
		font-family: $font-3;
		color: #fff;
		font-size: 105px;
		top: 30%;
		width: 100%;
		text-align: center;
		margin: 0 auto;
		position: absolute;
	}
	@media screen and (max-width: 768px) {
		height: 320px;
		h1 {
			font-size: 36px;
			top: 25%;
		}
	}
}
@mixin h2-style () {
	font-family: $font-2;
	font-size: 48px;
	font-weight: 700;
	@media screen and (max-width: 768px) {
		font-size: 30px;
	}
}
@mixin text-style($size,$family,$weight,$color) {
	font-family: $family;
	font-size: $size;
	font-weight: $weight;
	color: $color;
}
@mixin ul-flech {
	padding-top: 60px;
	padding-left: 30px;
	list-style: none;
	li {
		padding: 8px 0;
		a {
			@include text-style(20px,$font-1,400,#174591);
			.arrow {
				background-image: url('../images/arrow.png');
				background-repeat: no-repeat;
				background-position: left;
				height: 15px;
				padding-right: 30px;
				left: 10px;
				position: absolute;
			}
		}
	}
	@media screen and (max-width: 768px) {
		padding-top: 30px;
		li {a {font-size: 16px;}
		}
	}
}
@mixin mobile-style {
	@media screen and (max-width: 768px) {
		padding: 60px 0;
		p {
			font-size: 16px;
			padding-bottom: 30px;
		}
		img {display: none;}
		ol {
			font-size: 16px;
		}

	}
}
.background {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.btn-contact {
	background-color: #174591;
	border: 1px solid #174591;
	border-radius: 40px;
	display: block;
	line-height: 20px;
	margin: 35px 0 0;
	padding: 25px 50px;
	max-width: 430px;
	text-align: center;
	@include text-style(30px,$font-2,700,#fff);
	&:hover {
		background-color: #fff;
		color: #000;
	}
}
/****************************************************************/
/************Photos page*****************************************/
/****************************************************************/
.top-photos {
	background-image: url('../images/head-photo.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-photo-m.jpg');
	}
}
.index-photos {
	padding: 140px 0;
	.item {
		padding: 10px;
		.grid-item-content {
			text-align: center;
			position: relative;
			.blue {
				text-align: center;
				background-color: rgba(24, 70, 147, 0.82);
				background-size: cover;
				top: 0;
				height: 100%;
				width: 100%;
				position: absolute;
				display: none;
				h3 {
					@include text-style(20px,$font-1,400, #fff);
					top: 40%;
					position: relative;
					text-align: center;
					-webkit-transform: translate(-50%, -50%);
					@include transform(translate(0%,-50%));
				}
			}
			&:hover {
				.blue {
					display: block;
				}
			}
		}
	}

}

/****************************************************************/
/************A la Une page****************************************/
/****************************************************************/
.top-une {
	background-image: url('../images/head-services.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-services-m.jpg');
	}
}


/****************************************************************/
/************History page****************************************/
/****************************************************************/
.top-hist {
	background-image: url('../images/head-histoire.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-ZONE-m.jpg');
	}
}
.index-hist {
	 background: -webkit-linear-gradient(top,#dcdcdc,#fff);
	padding: 140px 0;
	p {
		@include text-style(20px,$font-1,400, #000);
		line-height: 180%;
		padding-bottom: 50px;
	}
	img {padding-top: 115px;}
  @include mobile-style;
}

.section02-hist {
	background-image: url('../images/NotreHistoire-BG.jpg');
	@extend .background;
	padding: 125px 0;
	h2 {
		color: #184795;
		padding-bottom: 50px;
		@include h2-style;
	}
	ol {
		@include text-style(20px,$font-1,400,#000);
		line-height: 170%;
		padding-left: 20px;
	}
	@include mobile-style;
}
.section03-hist {
	background-image: url('../images/NotreHistoire-BG02.jpg');
	@extend .background;
	padding: 125px 0;
	h2 {
		color: #fff;
		padding-bottom: 50px;
		@include h2-style;
	}
	ol {
		@include text-style(20px,$font-1,400,#fff);
		line-height: 170%;
		padding-left: 20px;
	}
	@include mobile-style;
}
.section04-hist {
	background-image: url('../images/NotreHistoire-BG03.jpg');
	@extend .background;
	padding: 125px 0;
	h2 {
		color: #fff;
		padding-bottom: 50px;
		@include h2-style;
	}
	ol {
		@include text-style(20px,$font-1,400,#fff);
		line-height: 170%;
		padding-left: 20px;
	}
	@include mobile-style;
}
/****************************************************************/
/************Mission page****************************************/
/****************************************************************/
.circle {
        background-repeat: no-repeat;
        background-position: left;
        background-size: cover;
        height: 60px;
        width: 60px;
        margin-left: 50px;
        @media screen and (max-width: 768px) {
        margin-left: 0px;
        margin-top: 5px;
    }

}
.top-mission {
	background-image: url('../images/head-mission.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-mission-m.jpg');
	}
}
.index-mission {
	background: -webkit-linear-gradient(left,#e0e0e0,#fff,#e0e0e0);
	padding: 140px 0;
	table {
		line-height: 100%;
		list-style: none;
		tr {
			border-top: 1px solid #c4c4c4;
				@include text-style(20px,$font-1,400,#000);
				td {
					padding: 20px 0;
					.text {
						padding-left: 50px;
						padding-top: 10px;
					}
				}
				.circle1 {
					background-image: url('../images/1.png');
					@extend .circle;
				}
				.circle2 {
					background-image: url('../images/2.png');
					@extend .circle;
				}
				.circle3 {
					background-image: url('../images/3.png');
					@extend .circle;	top: 176px;
				}
				.circle4 {
					background-image: url('../images/4.png');
					@extend .circle;
				}
				.circle5 {
					background-image: url('../images/5.png');
					@extend .circle;
				}

		}
	}
	//@include mobile-style;
    @media screen and (max-width: 768px) {
        padding: 60px 0;
        table {
            tr {
                font-size: 16px;
                td {
                    .text {
                        padding-left: 10px;
                        padding-top: 0px;
                    }
                }
            }
        }
    }
}
/****************************************************************/
/************Projet page****************************************/
/****************************************************************/
.top-projet {
	background-image: url('../images/head-projet.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-projet-m.jpg');
	}
}
.index-projet {
	padding: 130px 0;
	p {
		@include text-style(20px,$font-1,400, #000);
		line-height: 150%;
	}
	h2 {
		@include h2-style;
		padding-bottom: 45px;
	}
	@include mobile-style;
}
.section02-projet {
	background-image: url('../images/NotreProjet-BG.jpg');
	@extend .background;
	padding: 140px 0;
	p {
		@include text-style(20px,$font-1,400, #000);
		line-height: 180%;
		padding-bottom: 50px;
	}
	img {padding-bottom: 55px;}
	@include mobile-style;
    @media screen and (max-width: 991px) {
    .btn-contact {
        padding: 10px 50px;
        font-size: 20px;
        }
	}
    @media screen and (max-width: 768px) {
        .btn-contact {display: none;}
	}
}
.section03-projet {
	background-image: url('../images/NotreHistoire-BG02.jpg');
	@extend .background;
	padding: 140px 0;
	h2 {
		color: #fff;
		padding-bottom: 50px;
		@include h2-style;
	}
	ol {
		@include text-style(20px,$font-1,400,#fff);
		line-height: 170%;
		padding-left: 20px;
	}
	@include mobile-style;
}
/****************************************************************/
/************page prescolaire*************************************/
/****************************************************************/
.top-prescolaire{
	background-image: url('../images/head-prescolaire.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-prescolaire-m.jpg');
	}
}
//index prescolaire et index primaire
.index-prescolaire {
	text-align: center;
	padding: 140px 0;
	p {
		@include text-style(20px,$font-1,400, #000);
		line-height: 150%;
	}
	h2{
		@include h2-style;
		padding-bottom: 45px;
	}
	
	@include mobile-style;

	.video-text.row {
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    @media screen and (max-width: 1240px) {
			flex-direction: column-reverse;
			.col-xs-12.col-sm-10.text {
			    margin-bottom: 50px;
			}
		}
		@media screen and (max-width: 1024px) {
			.col-xs-12.col-sm-6.video {
			    width: 100%;
			}
		}
		@media screen and (max-width: 768px) {
			.col-xs-12.col-sm-10.text {
			    margin-bottom: 0;
			}
		}
		@media screen and (max-width: 585px) {
			.col-xs-12.col-sm-6.video {
				iframe {
				    width: 380px;
				    height: 245px;
				}
			}
		}
		@media screen and (max-width: 360px) {
			.col-xs-12.col-sm-6.video {
				iframe {
				    width: 325px;
				    height: 200px;
				}
			}
		}
	}

	.col-xs-12.col-sm-10.col-sm-push-1.text-box {
	    text-align: initial;
	    padding-top: 100px;
	    h2 {
	    	text-align: center;
	    }
	    ul {
	    	padding-left: 25px;
    		padding-top: 5px;
	    	li {
	    		font-family: "Roboto";
			    font-size: 20px;
			    font-weight: 400;
			    color: #000;
			    line-height: 150%;
	    	}
	    }
	    @media screen and (max-width: 768px) {
			ul {
				li {
					font-size: 16px;
				}
			}
			p {
				padding-bottom: 0;
			}
		}
	}
}
.section02-prescolaire {
	background-image: url('../images/prescolaire-img.png');
	@extend .background;
	padding: 140px 0;
	h2 {
		@include h2-style;
		padding-bottom: 45px;
	}
	h3 {
		font-family: "Dosis";
	    font-size: 40px;
	    font-weight: 700;
	    margin-top: 5px;
	}
	ol {
		@include text-style(20px,$font-1,400,#000);
		line-height: 170%;
		padding-left: 20px;
	}
	ul {@include ul-flech;}
	@include mobile-style;
	@media screen and (max-width: 768px) {
		h3 {
			font-size: 22px;
		}	
	}
}
/****************************************************************/
/************page primaire*************************************/
/****************************************************************/
.top-primaire{
	background-image: url('../images/head-primaire.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-primaire-m.jpg');
	}
}
.index-primaire {@extend .index-prescolaire;}
.section02-primaire{
	padding: 130px 0;
	background-color: #e5e5e5;
	p {
		@include text-style(20px,$font-1,400, #000);
		line-height: 150%;
	}
	ol {
		@include text-style(20px,$font-1,400,#000);
		line-height: 170%;
		padding-left: 20px;
	}
	ul {@include ul-flech;}
	p {
		@include text-style(20px,$font-1,400, #000);
		line-height: 180%;
		padding-bottom: 50px;
	}
		@include mobile-style;
}

/****************************************************************/
/************page service aux eleves*****************************/
/****************************************************************/
.top-s-eleves{
	background-image: url('../images/head-services.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-services-m.jpg');
	}
}
.index-s-eleves {
		text-align: center;
		padding: 140px 0;
	h2 {@include h2-style;}
	@include mobile-style;
}
.section02-s-eleves {
  background: -webkit-linear-gradient(top,#eeeeee,#fff);
	padding-top: 140px;
	padding-bottom: 0;
	h2 {
		@include h2-style;
		color: #e1503a;
		padding-bottom: 50px;
	}
	p {
		@include text-style(20px,$font-1,400, #000);
		line-height: 150%;
	}
	@include mobile-style;
	@media screen and (max-width: 768px) {
		background-color: #eeeeee;
		background-image: inherit;
	}
}
.section03-s-eleves {
	background-image: url('../images/BG-services.jpg');
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	padding-bottom: 140px;
	h2 {
		@include h2-style;
		color: #e1503a;
		padding-bottom: 50px;
	}
	p {
		@include text-style(20px,$font-1,400, #000);
		line-height: 150%;
	}
	@include mobile-style;
	@media screen and (max-width: 768px) {
		background-color: #eeeeee;
		background-image: inherit;
	}
}
/****************************************************************/
/************page Personnel*************************************/
/****************************************************************/
.top-personnel {
	background-image: url('../images/head-personnel.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-personnel-m.jpg');
	}
}
.index-personnel {
	padding: 140px 0;
	.enseignant {padding-top: 130px;}
	h2 {
		@include h2-style;
		padding-bottom: 50px;
		text-align: center;
	}
	.table {
		@include text-style(18px,$font-1,400, #000);
		line-height: 100%;
		tr {
			border-top: 1px solid #c4c4c4;
			&:nth-last-child(1) {border-bottom: 1px solid #c4c4c4;}
		}
	}
    .table1 {
        display: none;
        @include text-style(16px,$font-1,400, #000);
				tr {
						//border-top: 1px solid #c4c4c4;
					td {
							width: 767px;
							height: 40px;
					    line-height: 100%;
					}
					&:nth-child(3n+1){
						border-top: 1px solid #c4c4c4;
					}
					&:nth-last-child(1) {border-bottom: 1px solid #c4c4c4;}
				}
    }
	@include mobile-style;
    @media screen and (max-width: 768px) {
        .table1{display: block;}
        .table{display: none;}
	    	.enseignant {padding-top: 30px;}
	 }
}
/****************************************************************/
/************page Coordonees*************************************/
/****************************************************************/
.top-coordonees {
	background-image: url('../images/head-coordonnees.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-coordonnees.jpg');
	}
}
.index-coordonees {
	padding: 130px 0;
	h2 {
		@include h2-style;
		text-align: center;
	}
	@include mobile-style;
}
.section01-coordonees {
	background-color: #efefef;
	.container-fluid {
		padding: 0;
		p {
			@include text-style(20px,$font-1,400, #000);
			padding: 10px 0;
		}
		.email {
				color: #e1503a;
				padding-bottom: 40px;
		}
		h5 {@include text-style(30px,$font-2,700, #000);}
		.map {padding: 0;}
	}
	.content {padding: 180px 0  0 130px;}
	.contact-form {
		padding: 70px 0  0 130px;
		width: 70%;
		.form-control {
			margin: 10px 0;
			&:nth-child(4){
				margin-bottom: 20px;
			}
		}
		a {
	    padding-left: 31px;
			margin-top: 20px;
			&::before{
				content: " ";
				background-image: url('../images/arrow.png');
				background-repeat: no-repeat;
				position: absolute;
				background-position: left;
				height: 15px;
				padding-right: 30px;
				@include transform(translateX(-100%));
			}
				@include text-style(20px,$font-1,400,#174591);
		}
	}
	@media screen and (max-width: 768px) {
		.content {padding: 30px 0;}
		.contact-form {
			padding: 30px 0;
			width: 100%;
		}
	}
}
/****************************************************************/
/************page Inscription************************************/
/****************************************************************/
.top-inscription {
	background-image: url('../images/head-mission.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-mission-m.jpg');
	}
}
.index-inscription {
	padding: 130px 0;
	h2 {
		@include h2-style;
		text-align: center;
		padding-bottom: 60px;
	}
	ul {
		padding-top: 60px;
		padding-left: 30px;
		list-style: none;
		li {
			padding: 20px 0;
			border-top: 1px solid #c4c4c4;
			a {
				@include text-style(20px,$font-1,400,#174591);
				padding-left: 35px;
				.icon {
					background-image: url('../images/Sans-titre-1.png');
					background-repeat: no-repeat;
					background-position: left;
					height: 15px;
					padding-right: 30px;
					left: 45px;
					position: absolute;
				}
			}
			&:nth-last-child(1)
			{
				border-bottom: 1px solid #c4c4c4;
			}
		}
		@media screen and (max-width: 768px) {
			padding-top: 30px;
			li {a {font-size: 16px;}
			}
		}
	}
    @include mobile-style;
}
/****************************************************************/
/************page zone parents eleves****************************/
/****************************************************************/
.top-zone {
	background-image: url('../images/head-ZONE.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-ZONE-m.jpg');
	}
}
.index-zone {
	padding: 130px 0;
	h2 {
		@include h2-style;
		text-align: center;
		padding-bottom: 60px;
	}
	.list-group-item {
		@include text-style(36px,$font-2,700, #184795);
		border: none;
		border-top: 1px solid #c4c4c4;
		border-bottom: 1px solid #c4c4c4;
		padding: 20px 15px;
		line-height: 100%;
		&:nth-last-child(7) {border-bottom: 1px solid #c4c4c4;}
	}
	.sublinks {
		margin-top: 1px;
		ul {
			padding: 45px;
			list-style: none;
			li {
					margin-bottom: 10px;
				a {
					@include text-style(20px,$font-1,400, #808080);
					&::before{
						content: " ";
						background-image: url('../images/Sans-titre-1.png');
						background-repeat: no-repeat;
						position: absolute;
						background-position: left;
						height: 20px;
						padding-right: 30px;
						@include transform(translateX(-100%));
					}
				}
			}
		}
	}
	p {
			@include text-style(20px,$font-1,400, #808080);
			line-height: 100%;
			background-color: #f1f1f1;
			border: 1px solid #c4c4c4;
			padding: 30px 15px;
		}
	@media screen and (max-width: 768px) {
		padding: 60px 0;
		p {font-size: 16px;}
		.list-group-item {
			font-size: 20px;
		}
		.sublinks {
			ul {
				padding: 20px 30px;
				li{
					padding: 5px 0;
					a {font-size: 16px;}
				}
			}
		}
	}
}
/****************************************************************/
/************page zone parents eleves****************************/
/****************************************************************/
.top-s-traiteur {
	background-image: url('../images/head-traiteur.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-traiteur-m.jpg');
	}
}
.index-s-traiteur {
	background-image: url('../images/traiteur-bg.jpg');
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: 100% 50%;
	padding: 130px 0;
	p {
		@include text-style(20px,$font-1,400, #000);
		line-height: 150%;
		padding-bottom: 50px;
	}
	h5 {@include text-style(20px,$font-1,400, #000);}
	@include mobile-style;
}
/****************************************************************/
/************page sorties educatives*****************************/
/****************************************************************/
.top-sorties {
	background-image: url('../images/head-sortie.jpg');
	@include top-style;
	@media screen and (max-width: 768px) {
		background-image: url('../images/head-sortie-m.jpg');
	}
}
.index-sorties {
		padding: 130px 0;
	p {
		@include text-style(20px,$font-1,400, #000);
		line-height: 150%;
		text-align: center;
	}
	h2 {
		@include h2-style;
		text-align: center;
		padding-bottom: 60px;
	}
	@include mobile-style;
}
.section01-sorties, .section02-sorties,.section03-sorties,.section04-sorties{
	padding: 120px 0;
	background-color: #e5e5e5;
	h2 {
		@include h2-style;
		padding-bottom: 45px;
	}
	p {
		@include text-style(20px,$font-1,400, #000);
		line-height: 150%;
	}
	.row {
		padding-top: 20px;
		padding-bottom: 20px
	}
		@include mobile-style;
}
.section02-sorties, .section03-sorties{
	background-image: url('../images/sortie-BG01.jpg');
	@extend .background;
	color: #fff;
	p {
		@include text-style(20px,$font-1,400, #fff);
	}
	@include mobile-style;
}
.section03-sorties {
	background-image: url('../images/sortie-BG02.jpg');
	p {
		padding-bottom: 50px;
		&:nth-last-child(1){
			padding-bottom: 0;
		}
	}
	@include mobile-style;
}
.section04-sorties {
	background-image: url('../images/sortie-BG03.jpg');
	background-repeat: no-repeat;
}
/****************************************************************/
/************page formulaire de contact*****************************/
/****************************************************************/
.form-index {
	padding: 130px 0;
	.form-control{margin-bottom: 10px;}
	@media screen and (max-width: 768px) {
		padding: 50px 0;
	}
	@media screen and (max-width: 500px) {
		padding: 10px 0;
	}
}

.section-fb {
	padding-bottom: 70px;
}
